import Vue from 'vue';
import Router from 'vue-router';
import appConfig from '../../src/app.config';
import { api } from '@/state/services';

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      name: "Home",
      path: "/",
      component: () => import("./views/home"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Home",
      },
    },
    {
      name: "Login",
      path: "/login",
      component: () => import("./views/login"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Login",
      },
    },
    {
      name: "Entrando",
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Entrando",
      },
    },
    {
      name: "Sair",
      path: "/logout",
      redirect: "/login",
    },
    {
      name: "Cadastre-se",
      path: "/register",
      component: () => import("./views/register"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Cadastre-se",
      },
    },
    {
      name: "Cadastre-se › Indicação",
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Cadastre-se › Indicação",
      },
    },
    {
      name: "Esqueceu sua senha?",
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Esqueceu sua senha?",
      },
    },
    {
      name: "Alterar senha",
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › Alterar senha",
      },
    },
    {
      name: "Minha Conta",
      path: "/account",
      component: () => import("./views/account/my"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Minha Conta",
      },
    },
    {
      name: "Link de Indicação",
      path: "/account/indication",
      component: () => import("./views/account/indication"),
      meta: {
        title: appConfig.title + " › Link de Indicação",
        authRequired: true,
        keepAlive: false,
      },
    },
    {
      name: "Minha Senha",
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Minha Senha",
      },
    },
    {
      name: "Senha de Segurança",
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Senha de Segurança",
      },
    },
    {
      name: "Senha de Segurança › Recuperar",
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Senha de Segurança › Recuperar",
      },
    },
    {
      name: "Conta Bancária",
      path: "/account/bank",
      component: () => import("./views/account/bank"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Conta Bancária",
      },
    },
    {
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: {
        title: appConfig.title,
        authRequired: true,
      },
    },
    {
      name: "Carteira › Extrato",
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Extrato",
      },
    },
    {
      name: "Carteira › Saque",
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Saque",
      },
    },
    {
      name: "Carteira › Saque › Histórico",
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Carteira › Saque › Histórico",
      },
    },
    {
      name: "Rede › Indicados",
      path: "/network/sponsored",
      component: () => import("./views/network/sponsored"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Rede › Indicados",
      },
    },
    {
      name: "Rede › Equipe",
      path: "/network/team",
      component: () => import("./views/network/team"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Rede › Equipe",
      },
    },
    {
      name: "Relatórios › Indicação",
      path: "/reports/referral",
      component: () => import("./views/reports/referral"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Relatórios › Indicação",
      },
    },
    {
      name: "Downloads",
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Downloads",
      },
    },
    {
      name: "FAQ",
      path: "/faq",
      component: () => import("./views/faq"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › FAQ",
      },
    },
    {
      name: "Atendimento",
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Atendimento",
      },
    },
    {
      name: "Atendimento › Ver",
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Atendimento › Ver",
      },
    },
    {
      name: "Notas Fiscais › Criar",
      path: "/invoices",
      component: () => import("./views/invoices/listing"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Notas Fiscais › Criar",
      },
    },
    {
      name: "Notas Fiscais",
      path: "/invoices/create",
      component: () => import("./views/invoices/create"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Notas Fiscais",
      },
    },
    {
      name: "Doadores",
      path: "/donators",
      component: () => import("./views/donators/view"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Doadores",
      },
    },
    {
      name: "Sorteios › Números da Sorte",
      path: "/draws/numbers",
      component: () => import("./views/draws/numbers"),
      meta: {
        authRequired: true,
        title: appConfig.title + " › Sorteios › Números da Sorte",
      },
    },
    {
      name: "404",
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        authRequired: false,
        title: appConfig.title + " › 404",
      },
    },
    {
      path: "*",
      redirect: "/register*",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  document.title = to.meta.title;
  next();

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          if (
            response.data.user.donator &&
            response.data.user.donator.status == 'pending' &&
            to.name != "Doadores"
          ) {
            if (to.name == 'Notas Fiscais' || to.name == 'Notas Fiscais › Criar') {
              return next("/donators");
            }
          } else if (
            response.data.order &&
            response.data.order.id &&
            to.name != "Carteira › Pedido"
          ) {
            return next("/wallet/order/" + response.data.order.id);
          } else {
            next();
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  } else {
    next();
  }
});

export default router;